import i18next from 'i18next';

i18next.addResources('es', 'countrySelector', {
  allCountries: 'Todos los países',
  countries: 'País',
  argentina: 'Argentina',
  bolivia: 'Bolivia',
  brasil: 'Brasil',
  chile: 'Chile',
  colombia: 'Colombia',
  ecuador: 'Ecuador',
  bahamas: 'Bahamas',
  barbados: 'Barbados',
  belice: 'Belice',
  costa_rica: 'Costa Rica', // eslint-disable-line camelcase
  el_salvador: 'El Salvador', // eslint-disable-line camelcase
  guatemala: 'Guatemala',
  guyana: 'Guyana',
  french_guiana: 'Guyana Francesa',
  haiti: 'Haití',
  honduras: 'Honduras',
  jamaica: 'Jamaica',
  mexico: 'México',
  nicaragua: 'Nicaragua',
  panama: 'Panamá',
  paraguay: 'Paraguay',
  peru: 'Perú',
  dominican_republic: 'República Dominicana', // eslint-disable-line camelcase
  suriname: 'Suriname',
  trinidad_and_tobago: 'Trinidad y Tobago', // eslint-disable-line camelcase
  uruguay: 'Uruguay',
  venezuela: 'Venezuela',
  africa: 'África',
  north_america: 'América del Norte', // eslint-disable-line camelcase
  antartic: 'Antártida',
  asia: 'Asia',
  europe: 'Europa',
  oceania: 'Oceanía',
  belize: 'Belize'
});
