import snakeCase from 'lodash/snakeCase';
import mapKeys from 'lodash/mapKeys';
import pickBy from 'lodash/pickBy';

export const deepFreeze = o => {
  Object.freeze(o);

  Object.getOwnPropertyNames(o).forEach(prop => {
    if (
      Object.prototype.hasOwnProperty.call(o, prop) &&
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop]);
    }
  });

  return o;
};

export const transformQueryParams = filters =>
  Object.keys(filters).reduce((filtersResult, filterKey) => {
    const currentFilter = filters[filterKey];
    if (currentFilter instanceof Object) {
      return Array.isArray(currentFilter)
        ? { ...filtersResult, [filterKey]: currentFilter.join() }
        : {
            ...filtersResult,
            ...mapKeys(currentFilter, (value, key) => `${snakeCase(filterKey)}[${snakeCase(key)}]`)
          };
    }
    return {
      ...filtersResult,
      [snakeCase(filterKey)]: currentFilter
    };
  }, {});

const DATE_PARAMS = [
  'creation_date_range[start_moment]',
  'creation_date_range[end_moment]',
  'duration_date_range[start_moment]',
  'duration_date_range[end_moment]'
];

const DATE_PARAM_MIN_LENGTH = 10;

export const filterUnfinishedDateParams = filters =>
  pickBy(filters, (value, key) => !(DATE_PARAMS.includes(key) && value.length < DATE_PARAM_MIN_LENGTH));
