import { createTypes, completeTypes } from 'redux-recompose';

import {
  getArticles,
  getStarredArticle,
  getArticle,
  getArticleInterestContent
} from '~services/DigitalContent';

import { ARTICLES_TARGET, STARRED_ARTICLE_TARGET, ARTICLE_TARGET, ARTICLE_INTERES_TARGET } from './constants';

export const actions = createTypes(
  completeTypes([
    'GET_ARTICLES',
    'GET_STARRED_ARTICLE',
    'GET_ARTICLE',
    'CLEAR_ARTICLE',
    'ARTICLE_INTERES_TARGET'
  ]),
  '@@DIGITALCONTENT'
);

export const actionCreators = {
  getArticles: page => ({
    type: actions.GET_ARTICLES,
    target: ARTICLES_TARGET,
    service: getArticles,
    payload: page
  }),
  getStarredArticle: () => ({
    type: actions.GET_STARRED_ARTICLE,
    target: STARRED_ARTICLE_TARGET,
    service: getStarredArticle
  }),
  getArticle: id => ({
    type: actions.GET_ARTICLE,
    target: ARTICLE_TARGET,
    service: getArticle,
    payload: id
  }),
  clearArticle: () => ({
    type: actions.CLEAR_ARTICLE,
    target: ARTICLE_TARGET
  }),
  interestContent: articleId => ({
    type: actions.ARTICLE_INTERES_TARGET,
    target: ARTICLE_INTERES_TARGET,
    service: getArticleInterestContent,
    successSelector: ({ data }) => data.page,
    payload: articleId
  })
};
