import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import ROUTES from '~constants/routes';
import { getOgMetaTags, completeOgObject } from '~utils/seo';

function AuthenticatedRoute({
  /*
   * TODO Add this if you need it
   * device,
   */
  isPublicRoute,
  isPrivateRoute,
  // initialized,
  currentUser = false,
  component: Comp,
  containerClassName,
  title,
  description,
  ...props
}: any) {
  const ogTags = getOgMetaTags(completeOgObject({ title, description, url: props.location.pathname }));
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {ogTags}
      </Helmet>
      <Route
        {...props}
        // eslint-disable-next-line react/jsx-no-bind
        render={routeProps => {
          /*
           * TODO Add this if you need it
           * if (device.isMobile && !device.adviceSubmitted) {
           *   return <AppDownloader />;
           * }
           */
          if (currentUser) {
            if (isPublicRoute) {
              /*
               * TODO Add this if you need it
               * if (currentUser && isPublicRoute) {
               * do not allow logged users to access public routes. redirect to app
               */
              return (
                <Redirect
                  to={{
                    pathname: ROUTES.HOME,
                    state: { from: props.location }
                  }}
                />
              );
            }
          } else if (isPrivateRoute) {
            // Do not allow unlogged users to access app. redirect to signin
            return (
              <Redirect
                to={{
                  pathname: ROUTES.HOME,
                  state: { from: props.location }
                }}
              />
            );
          }

          return (
            <div className={containerClassName}>
              <Comp {...routeProps} />
            </div>
          );
        }}
      />
    </>
  );
}

export default withRouter(connect()(AuthenticatedRoute));
