import i18next from 'i18next';

i18next.addResources('es', 'whatWeDo', {
  sendera: 'Alianza por la Integración y el Desarrollo de América Latina y el Caribe.',
  ourTrails: 'Nuestros senderos',
  energy: 'Energía',
  border: 'Frontera',
  transportAndLogistics: 'Transporte y logística',
  telecommunications: 'Telecomunicaciones',
  realizationTitle: 'Realización de proyectos a través de la plataforma',
  displayTitle: 'Visualización de datos de forma transparente',
  opportunityTitle: 'Oportunidad de expandir tus conexiones',
  spacesTitle: 'Espacios de capacitación y aprendizaje',
  realizationText:
    'ILAT brindará las herramientas y el acompañamiento para la realización eficaz de proyectos regionales garantizando la transparencia de la información y la eficiencia en las inversiones.',
  displayText:
    'Pondremos al alcance de los usuarios datos públicos en formatos abiertos para que puedan visualizarlos, usarlos, actualizarlos y compartirlos, permitiendoles así sacar sus propias hipótesis y conclusiones.',
  opportunityText:
    'ILAT permitirá y fomentará la conexión y el intercambio entre actores públicos, privados y reconocidos expertos en las áreas de interés, permitiendo el enriquecimiento de los participantes y la fluidez en el trabajo conjunto.',
  spacesText:
    'Propiciará la difusión de productos de conocimiento, generados por actores relevantes. A su vez ofrecerá capacitación en tecnología, así como también oportunidades de interacción y aprendizaje.',
  qualityData: 'Datos de calidad',
  interactiveVisualization: 'Visualización interactiva',
  intuitiveInterface: 'Interfaz intuitiva',
  betterPractices: 'Mejores prácticas',
  whatWeDo: 'Qué hacemos',
  senderaName: 'ILAT'
});
