import i18next from 'i18next';

i18next.addResources('es', 'navbar', {
  home: 'Inicio',
  digitalContent: 'Contenidos',
  projects: 'Proyectos',
  schedule: 'Agenda',
  whatWeDo: 'Alianza',
  events: 'Eventos',
  sendera: 'ILAT',
  projectCharts: 'Gráficos de proyectos'
});
