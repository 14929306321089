import i18next from 'i18next';

i18next.addResources('es', 'eventTypeSelector', {
  eventTypes: 'Tipos',
  workshop: 'Taller',
  meeting: 'Webinar',
  conference: 'Conferencia',
  training: 'Capacitación',
  seminar: 'Seminario'
});
