import i18next from 'i18next';

i18next.addResources('es', 'contentType', {
  // TODO: review this types with backend
  event: 'Evento',
  publication: 'Publicación',
  project: 'Proyecto',
  energy: 'Energía',
  transport: 'Transporte y logística',
  boundary: 'Fronteras',
  telecommunications: 'Telecomunicaciones',
  article: 'Artículo',
  workshop: 'Taller',
  infographic: 'Infografía',
  news: 'Noticias',
  publishing: 'Publicación',
  video: 'Video',
  hackathon: 'Hackaton',
  meeting: 'Webinar',
  conference: 'Conferencia',
  training: 'Capacitación',
  seminar: 'Seminario'
});
