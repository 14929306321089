import i18next from 'i18next';

i18next.addResources('es', 'eventDetail', {
  borders: 'Fronteras',
  eventsTitle: 'Eventos',
  workshopTitle: 'Taller',
  workshop: 'Taller Argentina Chile sobre acciones binacionales',
  place: 'Buenos Aires, Argentina',
  buttonCalendar: 'Agregar a mi calendario',
  previousEvent: 'Evento anterior',
  nextEvent: 'Próximo evento',
  downloadSchedule: 'Descargar agenda del evento',
  downloadPresentation: 'Descargar presentación',
  downloadRelatedMaterial: 'Descargar material relacionado',
  downloadMaterial: 'Descargar material ',
  textImg: 'Las relaciones bilaterales entre Argentina y Chile.',
  relatedEvents: 'Eventos relacionados',
  bilateralRelations:
    'Las relaciones bilaterales entre Argentina y Chile se han afianzado a lo largo de la últimas décadas con el propósito de mejorar la conectividad fronteriza. En ese marco, en noviembre de 2018 ambos países se reunieron en BID INTAL con sus equipos técnicos para ahondar en las temáticas priorizadas.'
});
