import { createTypes, completeTypes } from 'redux-recompose';

import {
  getEvent,
  getAllEvents,
  getStarredEvent,
  getRelatedEvent,
  getEventsByDate
} from '~services/EventDetailService';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(['GET_EVENT', 'GET_EVENTS', 'GET_STARRED_EVENT', 'GET_RELATED_EVENT', 'GET_DATE_EVENTS']),
  '@@EVENTS'
);

export default {
  getEvent: id => ({
    type: actions.GET_EVENT,
    target: TARGETS.EVENT,
    service: getEvent,
    payload: id
  }),
  getRelatedEvent: id => ({
    type: actions.GET_RELATED_EVENT,
    target: TARGETS.RELATED_EVENT,
    service: getRelatedEvent,
    payload: id
  }),
  getEvents: page => ({
    type: actions.GET_EVENTS,
    target: TARGETS.EVENTS,
    service: getAllEvents,
    payload: page
  }),
  getStarredEvent: () => ({
    type: actions.GET_STARRED_EVENT,
    target: TARGETS.STARRED_EVENT,
    service: getStarredEvent
  }),
  getEventsByDate: moment => ({
    type: actions.GET_DATE_EVENTS,
    target: TARGETS.DATE_EVENTS,
    service: getEventsByDate,
    payload: {
      startMoment: moment.startMoment,
      endMoment: moment.endMoment
    }
  })
};
