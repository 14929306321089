import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';
import { SEARCH_TARGET, POPULAR_SEARCH_TARGET } from './constants';

const STATE_DESCRIPTION = {
  [SEARCH_TARGET]: {},
  [POPULAR_SEARCH_TARGET]: {}
};

const initialState = completeState(STATE_DESCRIPTION);

const reducerDescription = {
  primaryActions: [actions.GET_ARTICLES, actions.POPULAR_SEARCH]
};

export default createReducer(initialState, completeReducer(reducerDescription));
