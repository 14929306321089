import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { deepFreeze } from '~utils/object';

import { actions } from './actions';
import { DEFAULT_STATE } from './constants';

const initialState = completeState(DEFAULT_STATE);

const reducerDescription = {
  primaryActions: [actions.CONTENT]
};

export default createReducer(deepFreeze(initialState), completeReducer(reducerDescription));
