import { createTypes, completeTypes } from 'redux-recompose';

import { getProjectDetail } from '~services/IIRSAProjectDetailService';

import { TARGETS } from './constants';

export const actions = createTypes(completeTypes(['GET_IIRSA_PROJECT_DETAIL']), '@@IIRSA_PROJECT_DETAIL');

export default {
  getProjectDetail: id => {
    return {
      type: actions.GET_IIRSA_PROJECT_DETAIL,
      target: TARGETS.PROJECT_DETAIL,
      service: getProjectDetail,
      payload: id
    };
  }
};
