import { lazy } from 'react';

const Events = lazy(() => import('~screens/Dashboard/screens/Events'));
const EventDetail = lazy(() => import('~screens/Dashboard/screens/EventDetail'));
const HomeScreen = lazy(() => import('~screens/Dashboard/screens/Home'));
const WhatWeDo = lazy(() => import('~screens/Dashboard/screens/WhatWeDo'));
const Projects = lazy(() => import('~screens/Dashboard/screens/Projects'));
const DigitalContent = lazy(() => import('~screens/Dashboard/screens/DigitalContent'));
const Search = lazy(() => import('~screens/Dashboard/screens/Search'));
const ProjectDetail = lazy(() => import('~screens/Dashboard/screens/Projects/screens/ProjectDetail'));
const Schedule = lazy(() => import('~screens/Dashboard/screens/Schedule'));
const ProjectTechnicalSheet = lazy(() =>
  import('~screens/Dashboard/screens/Projects/screens/ProjectDetail/screens/TechnicalSheet')
);
const ArticleDetail = lazy(() => import('~screens/Dashboard/screens/ArticleDetail'));
const ProjectsIIRSA = lazy(() => import('~screens/Dashboard/screens/ProjectsIIRSA'));
const IIRSAProjectDetail = lazy(() => import('~screens/Dashboard/screens/ProjectsIIRSA/screens/ProjectDetail'));
const Error404 = lazy(() => import('~screens/Dashboard/screens/Error404'));

export const ROUTES = {
  HOME: '/',
  // Should we change this route name to articles??
  DIGITAL_CONTENT: '/digital_content',
  DIGITAL_CONTENT_PAGE: '/digital_content/page/:page',
  ARTICLE_DETAIL: '/digital_content/:id',
  PROJECTS: '/project',
  PROJECT_DETAIL: '/project/:id',
  PROJECT_TECHNICAL_SHEET: '/project/:id/technical',
  CALENDAR: '/events/calendar',
  WHAT_WE_DO: '/what_we_do',
  EVENT_DETAIL: '/events/:id',
  SEARCH: '/search/:id',
  EVENTS: '/events',
  NOT_FOUND: '/404',
  ERROR404: '*',
  SEARCHER_ARTICLE: '/search/article',
  IIRSA_PROJECTS: '/portfolio',
  IIRSA_PROJECT_DETAIL: '/portfolio/:id'
};

export const NOT_SCROLL_IN_REDIRECT = 'notScrollInRedirect';

export const ROUTES_CONFIG = [
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.HOME,
    component: HomeScreen,
    title: 'SEO:homeTitle',
    description: 'SEO:homeDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.DIGITAL_CONTENT,
    component: DigitalContent,
    title: 'SEO:digitalTitle',
    description: 'SEO:digitalDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.DIGITAL_CONTENT_PAGE,
    component: DigitalContent,
    title: 'SEO:digitalTitle',
    description: 'SEO:digitalDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.EVENTS,
    component: Events,
    title: 'SEO:eventsTitle',
    description: 'SEO:eventsDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.PROJECTS,
    component: Projects,
    title: 'SEO:projectsTitle',
    description: 'SEO:projectsDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.PROJECT_DETAIL,
    component: ProjectDetail,
    title: 'SEO:projectDetailTitle',
    description: 'SEO:projectDetailDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.PROJECT_TECHNICAL_SHEET,
    component: ProjectTechnicalSheet,
    title: 'SEO:projectTechnicalTitle',
    description: 'SEO:projectTechnicalDescription'
  },
  /*{
    isPublicRoute: true,
    exact: true,
    path: ROUTES.CALENDAR,
    component: Schedule,
    title: 'SEO:scheduleTitle',
    description: 'SEO:scheduleDescription'
  },*/
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.WHAT_WE_DO,
    component: WhatWeDo,
    title: 'SEO:whatWeDoTitle',
    description: 'SEO:whatWeDoDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.EVENT_DETAIL,
    component: EventDetail,
    title: 'SEO:eventDetailTitle',
    description: 'SEO:eventDetailDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.ARTICLE_DETAIL,
    component: ArticleDetail,
    title: 'SEO:articleDetailTitle',
    description: 'SEO:articleDetailDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.SEARCH,
    component: Search,
    title: 'SEO:searchTitle',
    description: 'SEO:searchDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.IIRSA_PROJECTS,
    component: ProjectsIIRSA,
    title: 'SEO:searchTitle',
    description: 'SEO:searchDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.IIRSA_PROJECT_DETAIL,
    component: IIRSAProjectDetail,
    title: 'SEO:searchTitle',
    description: 'SEO:searchDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.NOT_FOUND,
    component: Error404,
    title: 'SEO:notFoundTitle',
    description: 'SEO:notFoundDescription'
  },
  {
    isPublicRoute: true,
    exact: true,
    path: ROUTES.ERROR404,
    component: Error404,
    title: 'SEO:notFoundTitle',
    description: 'SEO:notFoundDescription'
  }
];

export default ROUTES;
