import { createTypes, completeTypes } from 'redux-recompose';

import { getProjectDetail } from '~services/ProjectDetailService';

import { TARGETS } from './constants';

export const actions = createTypes(completeTypes(['GET_PROJECT_DETAIL']), '@@PROJECT_DETAIL');

export default {
  getProjectDetail: id => ({
    type: actions.GET_PROJECT_DETAIL,
    target: TARGETS.PROJECT_DETAIL,
    service: getProjectDetail,
    payload: id
  })
};
