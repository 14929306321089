import i18next from 'i18next';

i18next.addResources('es', 'technicalSheet', {
  general: 'General',
  countries: 'Países',
  area: 'Ámbito',
  subsector: 'Subsector',
  stateOfProject: 'Estado del proyecto',
  status: 'Etapa',
  estimatedEndDate: 'Fin estimado',
  ambientalLicense: 'Licencia ambiental',
  finance: 'Costo y financiamiento',
  totalInvestment: 'Inversión total',
  financing: 'Tipo',
  sources: 'Fuentes',
  responsableOfInformation: 'Responsables de información',
  name: 'Nombre',
  role: 'Cargo',
  email: 'e-mail',
  institution: 'Fuente',
  technicalSheet: 'Ficha Técnica',
  range: 'Alcance',
  objectives: 'Objetivos',
  description: 'Descripción',
  url: 'Enlace',
  info: 'info',
  yes: 'Si',
  no: 'No',
  public_project: 'Público', // eslint-disable-line camelcase
  private_project: 'Privado', // eslint-disable-line camelcase
  mixed_project: 'Mixto', // eslint-disable-line camelcase
  amount: 'Monto',
  firstName: 'Nombre',
  lastName: 'Apellido',
  documents: 'Documentos',
  relatedProjects: 'Proyectos relacionados',
  phone: 'Teléfono',
  ilat: 'ILAT'
});
