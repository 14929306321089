import { createMiddleware as createAnalyticsMiddleware } from 'redux-beacon';
import GoogleAnalytics, { trackPageView } from '@redux-beacon/google-analytics';

const pageView = trackPageView(action => ({
  page: action.payload.location.pathname
}));

const eventsMap = {
  '@@router/LOCATION_CHANGE': pageView
};

// eslint-disable-next-line new-cap
export default createAnalyticsMiddleware(eventsMap, GoogleAnalytics());
