export const TARGETS = {
  EVENT: 'event',
  RELATED_EVENT: 'relatedEvent',
  EVENTS: 'events',
  STARRED_EVENT: 'starredEvent',
  DATE_EVENTS: 'dateEvents'
};

export const DEFAULT_STATE = {
  event: null,
  relatedEvent: null,
  events: {},
  starredEvent: {},
  dateEvents: null
};
