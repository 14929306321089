import intalLogo from '~assets/LogoIntal_Transparente.png';
import cafLogo from '~assets/caf.png';
import fcnLogo from '~assets/fcn.png';
import bidLogo from '~assets/Logo_BID_sin bajada.png';

import styles from './styles.module.scss';

export const ITEMS = [
  {
    logo: bidLogo,
    itemClassname: styles.bid,
    name: 'bid logo',
    src: 'https://www.iadb.org'
  },
  {
    logo: cafLogo,
    itemClassname: styles.caf,
    name: 'caf logo',
    src: 'https://www.caf.com'
  },
  {
    logo: fcnLogo,
    itemClassname: `${styles.fcn}`,
    name: 'fonplata logo',
    src: 'https://www.fonplata.org '
  }
  // {
  //   logo: intalLogo,
  //   itemClassname: styles.intal,
  //   name: 'intal logo',
  //   src: 'https://www.iadb.org/intal'
  // }
];
