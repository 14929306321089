import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as form } from 'redux-form';
import thunk from 'redux-thunk';
import { fetchMiddleware } from 'redux-recompose';
import { adminReducer, i18nReducer, adminSaga } from 'react-admin';

import { DEFAULT_OG_OBJECT } from '~constants/seo';

import AnalyticsMiddleware from '../services/AnalyticsService';

import { i18nProvider } from 'config/i18n';

import { reducer as auth } from './Auth/reducer';
import recommendedContent from './RecommendedContent/reducer';
import projectInterestContent from './ProjectInterestContent/reducer';
import featuredContent from './FeaturedContent/reducer';
import featuredEvents from './FeaturedEvents/reducer';
import projects from './Projects/reducer';
import projectsIIRSA from './ProjectsIIRSA/reducer';
import projectDetail from './ProjectDetail/reducer';
import iirsaProjectDetail from './IIRSAProjectDetail/reducer';
import events from './Events/reducer';
import digitalContent from './DigitalContent/reducer';
import search from './Search/reducer';
import modal from './Modal/reducer';

import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import dataProvider from 'config/providers/dataProvider';

export const history = createBrowserHistory();

const rootSaga = function* rootSaga() {
  yield all([adminSaga(dataProvider, i18nProvider)].map(fork));
};

const sagaMiddleware = createSagaMiddleware();

// Add reducers here
const reducers = combineReducers({
  router: connectRouter(history),
  form,
  recommendedContent,
  projectInterestContent,
  auth,
  projects,
  projectsIIRSA,
  digitalContent,
  search,
  featuredContent,
  projectDetail,
  iirsaProjectDetail,
  events,
  featuredEvents,
  modal,
  admin: adminReducer,
  i18n: i18nReducer(DEFAULT_OG_OBJECT.locale, i18nProvider(DEFAULT_OG_OBJECT.locale))
});

const middlewares = [thunk, fetchMiddleware, routerMiddleware(history), AnalyticsMiddleware, sagaMiddleware];
const enhancers: any[] = [];

/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middlewares));

// eslint-disable-next-line dot-notation
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

const rootReducer = (state, action) =>
  /*
   * TODO Add this if you need it
   * if (action.type === authActions.SIGN_OUT) {
   *   return reducers(getGlobalState(state), action);
   * }
   */
  reducers(state, action);

const store = createStore(rootReducer, composeEnhancers(...enhancers));

sagaMiddleware.run(rootSaga);

export default store;
