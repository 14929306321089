import { createTypes } from 'redux-recompose';

import { getFeaturedContent } from '~services/FeaturedContent';

import { TARGETS } from './constants';

export const actions = createTypes(['CONTENT'], '@@FEATURED');

export const actionCreators = {
  featuredContent: () => ({
    type: actions.CONTENT,
    target: TARGETS.CONTENT,
    service: getFeaturedContent,
    successSelector: ({ data }) => data.page
  })
};

export default actionCreators;
