import { createTypes, completeTypes } from 'redux-recompose';

import { getProjectInterestContent } from '~services/ProjectInterestContent';

import { TARGETS } from './constants';

export const actions = createTypes(completeTypes(['CONTENT']), '@@INTEREST');

export default {
  interestContent: projectId => ({
    type: actions.CONTENT,
    target: TARGETS.CONTENT,
    service: getProjectInterestContent,
    successSelector: ({ data }) => data.page,
    payload: projectId
  })
};
