import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';
import { DEFAULT_STATE } from './constants';

const initialState = completeState(DEFAULT_STATE);

const reducerDescription = {
  primaryActions: [
    actions.GET_EVENT,
    actions.GET_EVENTS,
    actions.GET_STARRED_EVENT,
    actions.GET_RELATED_EVENT,
    actions.GET_DATE_EVENTS
  ]
};

export default createReducer(initialState, completeReducer(reducerDescription));
