import { createTypes } from 'redux-recompose';

import { getRecommendedContent } from '~services/RecommendedContentService';

import { TARGETS } from './constants';

export const actions = createTypes(['ARTICLES'], '@@RECOMENDATIONS');

export const actionCreators = {
  recommendedContent: () => ({
    type: actions.ARTICLES,
    target: TARGETS.ARTICLES,
    service: getRecommendedContent,
    successSelector: ({ data }) => data.page
  })
};

export default actionCreators;
