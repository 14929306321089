import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { ITEMS } from './constants';
import bidCafFonplata from 'assets/bid_caf_fonplata_footer.svg';
import bidLogo from 'assets/bid_footer.svg';
import cafLogo from 'assets/caf_footer.svg';
import fonplataLogo from 'assets/fonplata_footer.svg';
import logoBlanco from 'assets/ILAT-logo-blanco.svg';
import ROUTES from "constants/routes";
import i18next from "i18next";
import fbLogo from '~assets/social-facebook.svg';
import twLogo from '~assets/social-twitter.svg';
import ytLogo from '~assets/social-yt.svg';

function Footer() {
  return (
    <div className={styles.footer}>
        <div className={`${styles.desktop}`}>
            <div className={styles.logoContainer}>
                <img src={logoBlanco}/>
            </div>
            <div className={styles.copyright}>
                &copy; 2020 ILAT<br/>
                Todos los derechos reservados
            </div>
        </div>
        <div className={styles.linksContainer}>
            <div className={`row ${styles.fRow} ${styles.mobile}`}>
                <div className={` col ${styles.logoContainer} ${styles.fCol}`}>
                    <img src={logoBlanco}/>
                </div>
            </div>
            <div className={`row ${styles.fRow} ${styles.mobile} ${styles.mb}`}>
                <div className={`row`}>
                    <span className={styles.contacto}>Contacto:</span>
                </div>
                <div className={`row`}>
                    <Link to={`mailto:info@alianzailat.org`}>info@alianzailat.org</Link>
                </div>
            </div>
            <div className={`row ${styles.fRow}`}>
                <div className={`col ${styles.fCol}`}>
                    <div className={`row`}>
                        <Link to={ROUTES.WHAT_WE_DO}>{i18next.t('navbar:whatWeDo')}</Link>
                    </div>
                    <div className={`row`}>
                        <Link to={ROUTES.PROJECTS}>{i18next.t('navbar:projects')}</Link>
                    </div>
                </div>
                <div className={`col ${styles.fCol} ${styles.mb}`}>
                    <div className={`row`}>
                        <Link to={ROUTES.DIGITAL_CONTENT}>{i18next.t('navbar:digitalContent')}</Link>
                    </div>
                    <div className={`row`}>
                        <Link to={ROUTES.EVENTS}>{i18next.t('navbar:events')}</Link>
                    </div>
                </div>
                <div className={`col ${styles.fCol} ${styles.desktop}`}>
                    <div className={`row`}>
                        <span className={styles.contacto}>Contacto:</span>
                    </div>
                    <div className={`row`}>
                        <Link to={`mailto:info@alianzailat.org`}>info@alianzailat.org</Link>
                    </div>
                </div>
                <div className={`col ${styles.fCol} ${styles.mobile}`}>
                    {/*<a className={`${styles.socialLogoContainer}`} href="https://www.facebook.com/" target="_blank">
                        <img src={fbLogo} className={styles.socialLogo}/>
                    </a>
                    <a className={`${styles.socialLogoContainer}`} href="https://twitter.com/" target="_blank">
                        <img src={twLogo} className={styles.socialLogo}/>
                    </a>
                    <a className={`${styles.socialLogoContainer}`} href="https://www.youtube.com/" target="_blank">
                        <img src={ytLogo} className={styles.socialLogo}/>
                    </a>*/}
                </div>
                
            </div>
            <div className={`row`}>
                <div className={`col ${styles.imgCont}`}>
                    <a href="https://www.iadb.org/" target="_blank" className={`${styles.logoBanco} ${styles.negMarginLeft}`}>
                        <img src={bidLogo} className={styles.logoBanco}/>
                    </a>
                    <a href="https://www.caf.com/" target="_blank" className={styles.logoBanco}>
                        <img src={cafLogo} className={styles.logoBanco}/>
                    </a>
                    <a href="https://www.fonplata.org/" target="_blank" className={styles.logoBanco}>
                        <img src={fonplataLogo} className={styles.logoBanco} />
                    </a>
                </div>
                <div className={`col ${styles.fCol} ${styles.desktop}`}>
                    {/*<a className={`${styles.socialLogoContainer}`} href="https://www.facebook.com/">
                        <img src={fbLogo} className={styles.socialLogo}/>
                    </a>
                    <a className={`${styles.socialLogoContainer}`} href="https://www.facebook.com/">
                        <img src={twLogo} className={styles.socialLogo}/>
                    </a>
                    <a className={`${styles.socialLogoContainer}`} href="https://www.facebook.com/">
                        <img src={ytLogo} className={styles.socialLogo}/>
                    </a>*/}
                </div>
            </div>
            <div className={`row ${styles.fRow} ${styles.mobile} ${styles.rights}`}>
                <div className={styles.copyright}>
                    &copy; 2020 ILAT<br/>
                    Todos los derechos reservados
                </div>
            </div>
        </div>
    </div>
  );
}

export default Footer;
