import i18next from 'i18next';

i18next.addResources('es', 'projectDetail', {
  seeTechnicalSheet: 'Ver ficha técnica',
  objectives: 'Objetivos',
  zoomMap: 'Ampliar mapa',
  lastUpdate: 'Ultima actualización ',
  countries: 'Países',
  dollarsInvested: 'Inversión estimada en Millones de dólares',
  projects: 'Proyectos',
  projectDetail: 'Detalle de Proyecto',
  interestContent: 'También podría interesarte',
  technicalSheet: 'Ficha Técnica',
  documents: 'Documentos',
  images: 'Imágenes',
  links: 'Links'
});
