import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { NOT_SCROLL_IN_REDIRECT } from '~constants/routes';

class ScrollToTop extends Component<RouteComponentProps> {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location && !(location.state && location.state[NOT_SCROLL_IN_REDIRECT])) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
