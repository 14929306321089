import { createReducer, completeReducer, completeState, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { HOVERED_PROJECT_TARGET, SELECTED_PROJECT_TARGET, STATE_DESCRIPTION } from './constants';

const initialState = completeState(STATE_DESCRIPTION, [HOVERED_PROJECT_TARGET, SELECTED_PROJECT_TARGET]);

const reducerDescription = {
  primaryActions: [actions.GET_PROJECTS],
  override: {
    [actions.SET_HOVERED_PROJECT_ID]: onReadValue(),
    [actions.SET_SELECTED_PROJECT_ID]: onReadValue()
  }
};

export default createReducer(initialState, completeReducer(reducerDescription));
