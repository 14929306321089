import { create } from 'apisauce';
import { replace } from 'connected-react-router';

import ROUTES from '~constants/routes';

import { serializer, deserializer } from './serializer';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const STATUS_CODES = {
  unauthorized: 401,
  notFound: 404
};

const api = create({
  baseURL: BASE_URL,
  timeout: 15000
});

// eslint-disable-next-line no-unused-vars, prettier/prettier
export const apiSetup = dispatch => {
  api.addMonitor(response => {
    if (response.status === STATUS_CODES.notFound) {
      dispatch(replace(ROUTES.NOT_FOUND));
    }
  });

  api.addMonitor(response => {
    if (response.problem === 'NETWORK_ERROR') {
      // TODO: These callbacks should only be called if no other callback was asigned for the response.
    }
  });

  api.addResponseTransform(response => {
    if (response.data) {
      response.data = deserializer.serialize(response.data);
    }
  });

  api.addRequestTransform(request => {
    if (request.data) {
      request.data = serializer.serialize(request.data);
    }
  });
};

export default api;
