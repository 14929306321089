import i18next from 'i18next';

i18next.addResources('es', 'articleDetail', {
  video: 'Video',
  infographic: 'Infografía',
  article: 'Artículo',
  notes: 'Nota',
  news: 'Noticia',
  publishing: 'Publicación',
  by: 'Por {{- authorName}}',
  date: ' · {{- date}}',
  error: 'No encontramos el contenido que estabas buscando',
  interestContent: 'También podría interesarte',
  content: 'Contenidos',
  source: 'Fuente',
  sourceUrl: 'Publicación original'
});
