import api from '../config/api';

export const getEvent = id => api.get(`/events/${id}`);

export const getRelatedEvent = id => api.get(`/events/${id}/related`);

export const getAllEvents = page => api.get(`/events?starred_at=false${page ? `&page=${page}` : ''}`);

export const getStarredEvent = () => api.get('/contents/starred?type=event');

export const getEventsByDate = moment =>
  api.get(`events/search?start_moment=${moment.startMoment}&end_moment=${moment.endMoment}`);
