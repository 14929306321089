import { createTypes, completeTypes } from 'redux-recompose';
import shuffle from 'lodash/shuffle';

import { getProjects } from '~services/ProjectsService';

import { PROJECTS_TARGET } from './constants';

export const actions = createTypes(
  completeTypes(['GET_PROJECTS'], ['SET_HOVERED_PROJECT_ID', 'SET_SELECTED_PROJECT_ID']),
  '@@PROJECTS'
);

export default {
  getProjects: searchParams => ({
    type: actions.GET_PROJECTS,
    target: PROJECTS_TARGET,
    service: getProjects,
    successSelector: ({ data }) => shuffle(data),
    payload: searchParams
  })
};
