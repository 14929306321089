import i18next from 'i18next';

i18next.addResources('es', 'stagesNames', {
  profiling: 'Perfil',
  // eslint-disable-next-line camelcase
  pre_execution: 'Pre-Ejecución',
  execution: 'Ejecución',
  finished: 'Concluido'
});

i18next.addResources('es', 'SEO', {
  homeTitle: 'ILAT',
  homeDescription: 'ILAT innovamos en infraestructura de integración.',
  projectsTitle: 'Proyectos | ILAT',
  projectsDescription:
    'Proyectos de ILAT desarrollados, en desarrollo o próximos a desarrollar de energía, fronteras, telecomunicaciones y transporte.',
  digitalTitle: 'Contenidos | ILAT',
  digitalDescription: 'Encuentra publicaciones, videos, noticias y más publicados por ILAT.',
  eventsTitle: 'Eventos | ILAT',
  eventsDescription: 'Encuentra conferencias, seminarios, talleres y más organizados por ILAT.',
  projectDetailTitle: 'Detalle de Proyecto | ILAT',
  projectDetailDescription: 'Detalle del proyecto organizado por ILAT.',
  projectTechnicalTitle: 'Ficha Técnica de Proyecto | ILAT',
  projectTechnicalDescription: 'Detalle de la ficha técnica del proyecto organizado por ILAT.',
  scheduleTitle: 'Calendario del Evento | ILAT',
  scheduleDescription: 'Calendario de la conferencia ó seminario ó taller organizado por ILAT.',
  whatWeDoTitle: 'Qué hacemos | ILAT',
  whatWeDoDescription: 'ILAT es tecnología puesta al servicio de la infraestructura de integración.',
  eventDetailTitle: 'Detalle de Evento | ILAT',
  eventDetailDescription: 'Detalle de la conferencia ó seminario ó taller organizado por ILAT.',
  articleDetailTitle: 'Detalle de Contenido | ILAT',
  articleDetailDescription: 'Detalle de la publicación ó video ó noticia publicado por ILAT.',
  searchTitle: 'Búsqueda | ILAT',
  searchDescription:
    'Resultado de la búsqueda aplicando filtros para buscar un evento, contenido o proyecto de ILAT.',
  iirsaProjectsTitle: 'Proyectos IIRSA | ILAT',
  iirsaProjectsDescription:
    'Proyectos de IIRSA desarrollados, en desarrollo o próximos a desarrollar de energía, fronteras, telecomunicaciones y transporte.',
  notFoundTitle: 'Recurso no encontrado | ILAT',
  notFoundDescription: 'Página 404 sin registros encontrados.'
});
