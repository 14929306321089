import { createReducer, completeReducer } from 'redux-recompose';

import { deepFreeze } from '~utils/object';

import { actions } from './actions';
import { DEFAULT_STATE } from './constants';

const reducerDescription = {
  primaryActions: [actions.CONTENT]
};

export default createReducer(deepFreeze(DEFAULT_STATE), completeReducer(reducerDescription));
