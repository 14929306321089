export const statusChoices = [{ id: 1, name: 'CON AVANCES' }, { id: 2, name: 'SIN AVANCES' }];

export const infraestructureChoices = [
  { id: 'energy', name: 'Energía' },
  { id: 'transport', name: 'Transporte y logística' },
  { id: 'boundary', name: 'Fronteras' },
  { id: 'telecommunications', name: 'Telecomunicaciones' }
];

export const sectorChoices = [
  {
    id: 2,
    name: 'Energía',
    subsectors: [{ id: 8, name: 'Interconexión Energética' }, { id: 9, name: 'Generación Energética' }]
  },
  { id: 3, name: 'Fronteras', subsectors: [] },
  { id: 4, name: 'Telecomunicaciones', subsectors: [] },
  {
    id: 1,
    name: 'Transporte',
    subsectors: [
      { id: 1, name: 'Aéreo' },
      { id: 2, name: 'Carretero' },
      { id: 3, name: 'Ferroviario' },
      { id: 4, name: 'Fluvial' },
      { id: 5, name: 'Pasos de Frontera' },
      { id: 6, name: 'Marítimo' },
      { id: 7, name: 'Multimodal' }
    ]
  },
];

// export const subsectorChoices = [
//   { id: 1, name: 'Transporte' },
//   { id: 2, name: 'Energía' },
// ];

export const fundingSourcesChoices = [
  { id: 16, name: 'A DEFINIR' },
  { id: 22, name: 'A DEFINIR - PUBLICO' },
  { id: 23, name: 'A DEFINIR - PRIVADO' },
  { id: 1, name: 'BID' },
  { id: 13, name: 'BINACIONAL' },
  { id: 2, name: 'CAF' },
  { id: 3, name: 'FONPLATA' },
  { id: 24, name: 'GOBIERNO CHINO' },
  { id: 4, name: 'GRUPO BANCO MUNDIAL' },
  { id: 15, name: 'PRIVADOS / CORPORATIVO' },
  { id: 8, name: 'TESORO NACIONAL' },
  { id: 5, name: 'UNION EUROPEA' },
  { id: 18, name: 'VARIOS' },
  { id: 19, name: 'VARIOS - PUBLICO' },
  { id: 21, name: 'VARIOS - PRIVADO' },
  { id: 11, name: 'TESORO PROVINCIAL' },
];

export const stagesChoices = [
  { id: 1, name: 'PERFIL' },
  { id: 2, name: 'PRE-EJECUCIÓN' },
  { id: 3, name: 'EJECUCIÓN' },
  { id: 4, name: 'CONCLUIDO' }
];
