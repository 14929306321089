import i18next from 'i18next';

i18next.addResources('es', 'instinfoHome', {
  whoWeAreTitle: 'Quiénes somos',
  whoWeAreText: 'La unión renovada de tres bancos de desarrollo comprometidos con la integración regional.',
  whatWeDoTitle: 'Qué hacemos',
  whatWeDoText: `Brindamos apoyo técnico y financiero a proyectos de infraestructura de integración. Generamos conocimiento y tecnología aplicada a la planificación e implementación de esos proyectos.`,
  whyWeDoItTitle: 'Por qué lo hacemos',
  whyWeDoItText: `Para consolidar el desarrollo y la integración de América Latina y el Caribe de forma sostenible y respetuosa con el medio ambiente.`,
  ourGoalTitle: 'Nuestro objetivo',
  ourGoalText: `Ser un espacio de trabajo para el desarrollo de infraestructura de integración de calidad e innovadora en un marco de transparencia y colaboración.`,
});
