import senderaLogo from '~assets/logo_sendera.png';

export const APP_URL = 'https://www.sendera.org';

export const DEFAULT_OG_OBJECT = {
  url: APP_URL,
  title: null,
  description: null,
  image: senderaLogo,
  type: 'website',
  locale: 'es',
  site_name: 'ILAT'
};
