import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';
import { DEFAULT_STATE } from './constants';

const initialState = completeState(DEFAULT_STATE);

const reducerDescription = {
  primaryActions: [actions.GET_PROJECT_DETAIL]
};

export default createReducer(initialState, completeReducer(reducerDescription));
