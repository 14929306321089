import { createTypes, completeTypes } from 'redux-recompose';
import shuffle from 'lodash/shuffle';

import { getProjects } from '~services/ProjectsService';

import { HOVERED_PROJECT_TARGET, SELECTED_PROJECT_TARGET, PROJECTS_TARGET } from './constants';

export const actions = createTypes(
  completeTypes(['GET_PROJECTS'], ['SET_HOVERED_PROJECT_ID', 'SET_SELECTED_PROJECT_ID']),
  '@@PROJECTS'
);

export default {
  getProjects: searchParams => ({
    type: actions.GET_PROJECTS,
    target: PROJECTS_TARGET,
    service: getProjects,
    successSelector: ({ data }) => shuffle(data),
    payload: searchParams
  }),
  setHoveredProjectId: payload => ({
    type: actions.SET_HOVERED_PROJECT_ID,
    target: HOVERED_PROJECT_TARGET,
    payload
  }),
  setSelectedProjectId: payload => ({
    type: actions.SET_SELECTED_PROJECT_ID,
    target: SELECTED_PROJECT_TARGET,
    payload
  })
};
