import React, { Component, lazy } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';
import cx from 'classnames';
import i18next from 'i18next';

import { history } from '~redux/store';
import ROUTES, { ROUTES_CONFIG } from '~constants/routes';
import ScrollToTop from '~components/ScrollToTop';

import Suspense from '../Suspense';
import Footer from '../Footer';

import AuthenticatedRoute from './components/AuthenticatedRoute';
import styles from './styles.module.scss';

const Navbar = lazy(() => import('../Navbar'));
class AppRoutes extends Component {
  renderRoutes: any = ({ path, title, description, ...props }) => (
    <AuthenticatedRoute
      key={path}
      path={path}
      containerClassName={cx({ [styles.container]: path !== ROUTES.HOME, [styles.projectsConteiner]: path === ROUTES.PROJECTS })}
      title={i18next.t(title)}
      description={i18next.t(description)}
      {...props}
    />
  );

  render() {
    return (
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <Suspense>
            <Navbar />
            <Switch>{ROUTES_CONFIG.map(this.renderRoutes)}</Switch>
            <Footer />
          </Suspense>
        </ScrollToTop>
      </ConnectedRouter>
    );
  }
}

export default AppRoutes;
