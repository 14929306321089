import i18next from 'i18next';

i18next.addResources('es', 'contentSelector', {
  allContents: 'Contenidos',
  contents: 'Contenidos',
  articles: 'Artículos',
  infographic: 'Infografías',
  notes: 'Notas',
  news: 'Noticias',
  publications: 'Publicaciones',
  videos: 'Videos'
});
