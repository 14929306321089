import i18next from 'i18next';

i18next.addResources('es', 'eventThemeSelector', {
  allThemes: 'Temas',
  bim: 'BIM',
  blockchain: 'Blockchain',
  artificialintelligence: 'Inteligencia Artificial',
  iot: 'Internet de las Cosas',
  bigdata: 'Big Data'
});
