import i18next from 'i18next';

i18next.addResources('es', 'stageSelector', {
  allStages: 'Todas las etapas',
  stages: 'Etapas',
  profile: 'Perfil',
  preExecution: 'Pre-ejecución',
  execution: 'Ejecución',
  finished: 'Concluido',
  apply: 'Aplicar'
});
