import { createTypes, completeTypes } from 'redux-recompose';

import { search } from '~services/search';

import { SEARCH_TARGET, POPULAR_SEARCH_TARGET } from './constants';

export const actions = createTypes(completeTypes(['GET_ARTICLES', 'POPULAR_SEARCH']), '@@SEARCH');

export const actionCreators = {
  search: searchParams => ({
    type: actions.GET_ARTICLES,
    target: SEARCH_TARGET,
    service: search,
    payload: searchParams
  }),
  popularSearch: () => ({
    type: actions.POPULAR_SEARCH,
    target: POPULAR_SEARCH_TARGET,
    service: search
  })
};
