import { GET_LIST } from 'react-admin';
import snakecase from 'lodash.snakecase';

import { transformQueryParams, filterUnfinishedDateParams } from '~utils/object';
import { statusChoices, infraestructureChoices } from '~constants/fieldGroups';

import api, { apiSetup } from '../api';
import oneToManyReducer from 'ra-core/esm/reducer/admin/references/oneToMany';

apiSetup();

export const formatProjectResponse = data =>
  data.map(item => ({
    ...item,
    id: item.code,
    // status: statusChoices.filter(c => c.id === item.status)[0].name
    // infrastructure: infraestructureChoices.filter(c => c.id === item.infrastructure)[0].name
  }));

export const parseResponse = rawResponse => ({
  data: rawResponse.data.page,
  total: rawResponse.data.totalCount
});

export default (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      const sharedParams = {
        ...filterUnfinishedDateParams(transformQueryParams(params.filter)),
        page: params.pagination.page,
        limit: params.pagination.perPage
      };
      let getParams = {
        ...sharedParams,
        [`order[${snakecase(params.sort.field)}]`]: params.sort.order
      };
      getParams = {
        ...sharedParams,
        sort: params.sort.field,
        order: params.sort.order
      };
      return api.get('/iirsa_projects', getParams).then(response => {
        if (response.ok) {
          if (resource === 'searchsSummary') {
            response.data.page = response.data.page.map(item => ({
              ...item,
              id: `${item.date}${item.searchString}`
            }));
          } else {
            response.data.page = formatProjectResponse(response.data.page);
          }
          return parseResponse(response);
        }
        throw response;
      });
    }
    default:
      throw new Error('provider.invalidAction');
  }
};
