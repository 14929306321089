import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';
import { ARTICLES_TARGET, STARRED_ARTICLE_TARGET, ARTICLE_TARGET, ARTICLE_INTERES_TARGET } from './constants';

const STATE_DESCRIPTION = {
  [ARTICLES_TARGET]: {},
  [STARRED_ARTICLE_TARGET]: {},
  [ARTICLE_TARGET]: {},
  [ARTICLE_INTERES_TARGET]: []
};

const initialState = completeState(STATE_DESCRIPTION);

const reducerDescription = {
  primaryActions: [
    actions.GET_ARTICLES,
    actions.GET_STARRED_ARTICLE,
    actions.GET_ARTICLE,
    actions.ARTICLE_INTERES_TARGET
  ],
  override: {
    [actions.CLEAR_ARTICLE]: (state, action) => ({
      ...state,
      [`${action.target}Loading`]: true,
      [`${action.target}Error`]: null,
      [`${action.target}`]: null
    })
  }
};

export default createReducer(initialState, completeReducer(reducerDescription));
