import i18next from 'i18next';
import englishMessages from 'ra-language-english';
import spanishMessages from 'aor-language-spanish';
import merge from 'lodash/merge';

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

i18next.init({
  lng: 'es',
  initImmediate: false
});

requireAll(require.context('..', true, /i18n\.tsx$/));

function getSpanishMessages() {
  spanishMessages.ra.action.confirm = 'Confirmar';
  spanishMessages.ra.message.delete_title = "Eliminar";
  spanishMessages.ra.message.delete_content = "¿Seguro que quiere eliminar este elemento?";
  spanishMessages.ra.message.bulk_delete_title = "Eliminar";
  spanishMessages.ra.message.bulk_delete_content = "¿Seguro que quiere eliminar este elemento? |||| ¿Seguro que quiere eliminar estos elementos?";
  spanishMessages.ra.message.export_title = "exportar";
  return spanishMessages;
}

export const messages = {
  en: { ...englishMessages },
  es: merge(getSpanishMessages())
};

export const i18nProvider = locale => messages[locale];
