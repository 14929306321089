import { createTypes } from 'redux-recompose';

import { getStarredEvent } from '~services/EventDetailService';

import { TARGETS } from './constants';

export const actions = createTypes(['GET_FEATURED_EVENTS'], '@@FEATURED_EVENTS');

export const actionCreators = {
  featuredEvents: () => ({
    type: actions.GET_FEATURED_EVENTS,
    target: TARGETS.EVENTS,
    service: getStarredEvent,
    successSelector: ({ data }) => data.page
  })
};

export default actionCreators;
