import i18next from 'i18next';

i18next.addResources('es', 'events', {
  title: 'Evento destacado',
  subtitle: 'Todos los eventos',
  titleResponsive: 'Eventos',
  nextEvent: 'Evento próximo',
  showCalendar: 'Ver calendario',
  searchPlaceholder: 'Encuentra conferencias, seminarios, talleres'
});
