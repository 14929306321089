import i18next from 'i18next';

i18next.addResources('es', 'instinfo', {
  misionTitle: 'Misión',
  mision1: `Promover la integración de las economías de América Latina y el Caribe a través de la realización de proyectos de infraestructura de calidad, eficientes, innovadores y sostenibles.`,
  mision2: 'Promover el desarrollo sostenible a través de proyectos de energía, integración fronteriza, telecomunicaciones, transporte y logística que contribuyan a mejorar la calidad de vida de los habitantes de la región.',
  mision3: 'Apoyar técnica y financieramente programas de integración y desarrollo territorial binacional y/o multinacional, con altos estándares de calidad, innovación, impacto y transparencia.',
  mision4: 'Producir conocimiento y tecnología aplicada a los proyectos de integración.',
  mision5: 'Fomentar la cooperación Sur-Sur y generar espacios de diálogo y apoyo entre los gobiernos, vinculando al sector público y privado.',
  visionTitle: 'Visión',
  visionText: `Ser el principal espacio de trabajo entre organismos multilaterales y países en torno a la infraestructura de integración involucrando la participación de especialistas sectoriales, gobiernos, academia, sector privado y sociedad civil.`,
  valuesTitle: 'Valores',
  valuesText: `Nuestros valores son:`,
  values1: 'Compromiso con la integración y el desarrollo sostenible',
  values2: 'Productividad y calidad',
  values3: 'Inclusión social',
  values4: 'Eficiencia',
  values5: 'Transparencia',
  values6: 'Innovación',
  values7: 'Resiliencia y adaptación',
  values8: 'Diálogo y colaboración',
});
